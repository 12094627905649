import React, { useEffect, useState } from "react";
import classes from "./VenueDetail.module.css";
import useWindowWidth from "helpers/useWindowWidth";
import VenueCarousel from "./VenueCarousel";
import PriceDetail from "./PriceDetail";
import ItemInfo from "./ItemInfo";
import Timings from "./Timings";
import DetailGallery from "./DetailGallery";
import DefaultSlider from "../home-content/DefaultSlider/DefaultSlider";
import ReviewSection from "./ReviewSection";
import { useParams } from "react-router-dom";
import { useMutation, useQuery } from "@tanstack/react-query";
import { SettingsDataKey, VenueDetailDataKey } from "helpers/queriesDataKeys";
import { API_BASE_URL } from "configs/AppConfig";
import { useDispatch, useSelector } from "react-redux";
import { message } from "antd";
import LoadingSpinner from "components/UI/loading-spinner/LoadingSpinner";
import { cartActions } from "store-redux/cart-slice";

const data = [
  {
    title: "The Dream Arena",
    city: "Kochi",
    state: "Kerala",
    price: 120000,
    strikePrice: 180000,
    review: 4.7,
    img: "/img/venue-banner.svg",
  },
  {
    title: "The Dream Arena Montreal Carnival",
    city: "Kochi",
    state: "Kerala",
    price: 120000,
    strikePrice: 180000,
    review: 4.7,
    img: "/img/venue-banner.svg",
  },
  {
    title: "The Dream Arena",
    city: "Kochi",
    state: "Kerala",
    price: 120000,
    strikePrice: 180000,
    review: 4.7,
    img: "/img/venue-banner.svg",
  },
  {
    title: "The Dream Arena Montreal Carnival",
    city: "Kochi",
    state: "Kerala",
    price: 120000,
    strikePrice: 180000,
    review: 4.7,
    img: "/img/venue-banner.svg",
  },
  {
    title: "The Dream Arena",
    city: "Kochi",
    state: "Kerala",
    price: 120000,
    strikePrice: 180000,
    review: 4.7,
    img: "/img/venue-banner.svg",
  },
];

const sampleVenueDetail = {
  title: "The Dream Arena , Kochi",
  priceDetails: {
    price: "80000",
    strikePrice: "90000",
    addOns: [
      { item: "AC", price: "7000" },
      { item: "Round Tables", price: "1020000" },
    ],
  },
  sampleImages: [
    "/img/login-wall.png",
    "/img/signup-wall.png",
    "/img/login-wall.png",
    "/img/signup-wall.png",
    "/img/login-wall.png",
    "/img/signup-wall.png",
    "/img/login-wall.png",
    "/img/signup-wall.png",
  ],
  sampleVideos: [
    {
      title: "Sample Video",
      videoUrl:
        "https://www.youtube.com/watch?v=KnIF85jtM1w&ab_channel=TamarindWeddings",
      videoId: "KnIF85jtM1w",
    },
    {
      title: "Sample Video",
      videoUrl:
        "https://www.youtube.com/watch?v=iy7Keg7oAYk&ab_channel=OLASAM%21",
      videoId: "iy7Keg7oAYk",
    },
    {
      title: "Sample Video",
      videoUrl:
        "https://www.youtube.com/watch?v=TpVvNHGn090&ab_channel=1Plus1Studio",
      videoId: "TpVvNHGn090",
    },
    {
      title: "Sample Video",
      videoUrl:
        "https://www.youtube.com/watch?v=0PhFbWAAkKM&ab_channel=EventManagementKerala%2CWeddingVenueThrissur",
      videoId: "0PhFbWAAkKM",
    },
    {
      title: "Sample Video",
      videoUrl:
        "https://www.youtube.com/watch?v=KnIF85jtM1w&ab_channel=TamarindWeddings",
      videoId: "KnIF85jtM1w",
    },
    {
      title: "Sample Video",
      videoUrl:
        "https://www.youtube.com/watch?v=iy7Keg7oAYk&ab_channel=OLASAM%21",
      videoId: "iy7Keg7oAYk",
    },
    {
      title: "Sample Video",
      videoUrl:
        "https://www.youtube.com/watch?v=TpVvNHGn090&ab_channel=1Plus1Studio",
      videoId: "TpVvNHGn090",
    },
    {
      title: "Sample Video",
      videoUrl:
        "https://www.youtube.com/watch?v=0PhFbWAAkKM&ab_channel=EventManagementKerala%2CWeddingVenueThrissur",
      videoId: "0PhFbWAAkKM",
    },
  ],
  description:
    "Qorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc vulputate libero et velit interdum, ac aliquet odio mattis. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos. Curabitur tempus urna at turpis condimentum lobortis. Qorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc vulputate libero et velit interdum, ac aliquet odio mattis. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos. Curabitur temp",
  availableDays: ["Monday", "Friday", "Saturday", "Thursday"],
  availableTimings: ["9 AM - 2 PM", "6 PM - 11 PM"],
  amenities: [
    "Outdoor Garden Oasis",
    "Decoration Packages",
    "Wedding Planning Services",
  ],
  ratingsData: {
    rating: 4.4,
    outOfRate: 5,
    noOfRatings: 27,
    noOfReviews: 3,
    reviews: [
      {
        name: "Deepa Nair",
        ratingGiven: 4,
        time: "2 years ago",
        comment:
          "Porem ipsum dolor sit amet, consectetur adipiscing elit. Nunc vulputate libero et velit interdum, ac aliquet odio mattis. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos. Curabitur tempus urna at turpis condimentum lobortis.",
      },
      {
        name: "Vishnu",
        ratingGiven: 3.5,
        time: "2 years ago",
        comment:
          "Porem ipsum dolor sit amet, consectetur adipiscing elit. Nunc vulputate libero et velit interdum, ac aliquet odio mattis. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos. Curabitur tempus urna at turpis condimentum lobortis.",
      },
      {
        name: "Niyas",
        ratingGiven: 5,
        time: "2 years ago",
        comment:
          "Porem ipsum dolor sit amet, consectetur adipiscing elit. Nunc vulputate libero et velit interdum, ac aliquet odio mattis. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos. Curabitur tempus urna at turpis condimentum lobortis.",
      },
      {
        name: "Damien",
        ratingGiven: 4.1,
        time: "2 years ago",
        comment:
          "Porem ipsum dolor sit amet, consectetur adipiscing elit. Nunc vulputate libero et velit interdum, ac aliquet odio mattis. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos. Curabitur tempus urna at turpis condimentum lobortis.",
      },
    ],
  },
};

const VenueDetail = () => {
  const windowWidth = useWindowWidth();
  const dispatch = useDispatch();
  const { user, isLoggedIn } = useSelector((state) => state.user);
  const filters = useSelector((state) => state.filter);
  console.log("user in venuedetail", user);
  const { venueId } = useParams();
  console.log("venueId", venueId);

  const [selectedAddOns, setSelectedAddOns] = useState([]);
  const [selectedSlotPrice, setSelectedSlotPrice] = useState(0);
  const [selectedInterval, setSelectedInterval] = useState("");
  console.log("selected interval", selectedInterval);
  const [dayValue, setDayValue] = useState("");
  console.log("dayValue", dayValue);
  const [intervals, setIntervals] = useState([]);
  console.log("intervals", intervals);
  const [selectedDate, setSelectedDate] = useState();
  console.log("selectedDate", selectedDate);
  const [similarInCategories, setSimilarInCategories] = useState([]);
  console.log("similarInCategories", similarInCategories);
  const [similarInLocations, setSimilarInLocations] = useState([]);
  console.log("similarInLocations", similarInLocations);
  const [noOfPeople, setNoOfPeople] = useState(undefined);

  const {
    isLoading,
    data: venueDetail,
    refetch: refetchListing,
  } = useQuery(
    [VenueDetailDataKey, venueId, user],
    async () => {
      const response = await fetch(`${API_BASE_URL}listing/${venueId}`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          // Authorization: `Bearer ${user.sessionToken}`,
        },
      });
      const data = await response.json();

      if (!response.ok) {
        message.error(data.message);
        throw new Error(data.message);
      }

      return data.data;
    }
    // {
    //   retry: 0, // Will retry failed requests 10 times before displaying an error
    //   retryDelay: 1000,
    //   initialData: [],
    // }
  );

  const fetchSimilarCategories = async () => {
    const response = await fetch(
      `${API_BASE_URL}listing/get/all?` +
        new URLSearchParams({
          latitude: filters?.latitude,
          longitude: filters?.longitude,
          categoryId: venueDetail?.category,
          range: 1000,
        }),
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          // Authorization: `Bearer ${user.sessionToken}`,
        },
      }
    );
    const data = await response.json();
    console.log("similar categories -", data);
    if (data.data) {
      const similarData = data.data;
      setSimilarInCategories(
        data.data.map((item) => ({
          title: item.name,
          city: item.location.locationName,
          state: "Kerala",
          price: item?.availableDates[0]?.intervals[0]?.price,
          strikePrice: 15000,
          review: 4.7,
          img: item.images[0],
          categoryName: item.categoryName,
          _id: item._id,
        }))
      );
    }

    if (!response.ok) {
      // message.error(data.message);
      throw new Error(data.message);
    }
  };

  const fetchBySimilarLocation = async () => {
    const response = await fetch(
      `${API_BASE_URL}listing/get/all?` +
        new URLSearchParams({
          latitude: filters?.latitude,
          longitude: filters?.longitude,
          range: 1000,
        }),
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          // Authorization: `Bearer ${user.sessionToken}`,
        },
      }
    );
    const data = await response.json();
    console.log("similar locations -", data);
    if (data.data) {
      const similarData = data.data;
      setSimilarInLocations(
        data.data.map((item) => ({
          title: item.name,
          city: item.location.locationName,
          state: "Kerala",
          price: item?.availableDates[0]?.intervals[0]?.price,
          strikePrice: 15000,
          review: 4.7,
          img: item.images[0],
          categoryName: item.categoryName,
          _id: item._id,
        }))
      );
    }

    if (!response.ok) {
      // message.error(data.message);
      throw new Error(data.message);
    }
  };

  useEffect(() => {
    if (venueDetail) {
      fetchSimilarCategories();
      fetchBySimilarLocation();
    }
  }, [venueDetail]);

  const getCartData = async () => {
    const response = await fetch(`${API_BASE_URL}cart/list/all`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${user.sessionToken}`,
      },
    });
    const data = await response.json();

    if (!response.ok) {
      // message.error(data.message);
      throw new Error(data.message);
    }

    dispatch(cartActions.toggleCart({ cartTotal: data.total }));
    // return data.data;
  };

  const { isLoading: addToCartLoading, mutateAsync: submitAddToCart } =
    useMutation(
      async () => {
        let payload = {
          listingId: venueDetail?._id,
          selectedDate: selectedDate,
          addOns: selectedAddOns,
        };
        if(noOfPeople){
          payload.numberOfPeople = Number(noOfPeople);
        }

        if (selectedAddOns.length > 0) {
          payload.addOns = selectedAddOns;
        }

        if (venueDetail?.slotType === "Time") {
          payload.start = selectedInterval.start;
          payload.end = selectedInterval.end;
          payload.slotNo = selectedInterval.slotNo;
        }

        if (venueDetail?.slotType === "Slot") {
          payload.slots = Number(1);
        }
        console.log("payload", payload);

        const response = await fetch(`${API_BASE_URL}cart/create`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${user.sessionToken}`,
          },
          body: JSON.stringify(payload),
        });

        const data = await response.json();
        console.log("response data,", data);
        if (!response.ok) {
          message.error(data.message);
          throw new Error(data.message);
        }

        // return data.data;
        if (data.success) {
          message.success("Added to Cart");
          getCartData();
          refetchListing();
        }
      }
      // { mutationKey: "FILES_MANGER" }
    );

  const compiledDays = venueDetail?.daysWithIntervals
    ?.map((obj) => obj.day)
    .flat();
  console.log("days combined", compiledDays);

  const compiledIntervals = venueDetail?.daysWithIntervals?.flatMap((obj) =>
    obj.intervals?.map((interval) => ({
      start: interval.start,
      end: interval.end,
    }))
  );
  console.log("timings combined", compiledIntervals);

  return (
    <>
      {isLoading && <LoadingSpinner />}
      {venueDetail && (
        <div className={classes.container}>
          <div className={classes.wrapper}>
            <div className={classes.venueInfo}>
              <VenueCarousel images={venueDetail?.images} />

              {/* Venue name and details */}
              <ItemInfo details={venueDetail} />

              {windowWidth < 1024 && (
                // PriceCard for mobile screen
                <div className={classes.priceDetails}>
                  <PriceDetail
                    details={venueDetail}
                    selectedAddOns={selectedAddOns}
                    setSelectedAddOns={setSelectedAddOns}
                    selectedInterval={selectedInterval}
                    setSelectedInterval={setSelectedInterval}
                    dayValue={dayValue}
                    setDayValue={setDayValue}
                    intervals={intervals}
                    setIntervals={setIntervals}
                    selectedDate={selectedDate}
                    setSelectedDate={setSelectedDate}
                    submitAddToCart={submitAddToCart}
                    addToCartLoading={addToCartLoading}
                    selectedSlotPrice={selectedSlotPrice}
                    setSelectedSlotPrice={setSelectedSlotPrice}
                    // details={sampleVenueDetail?.priceDetails}
                  />
                </div>
              )}

              {/* Venue weekdays and timings */}
              <Timings
                days={compiledDays}
                timings={compiledIntervals}
                details={venueDetail}
                // days={sampleVenueDetail?.availableDays}
                // timings={sampleVenueDetail?.availableTimings}
              />

              {/* Gallery of images and videos */}
              <DetailGallery
                images={venueDetail?.images}
                // images={sampleVenueDetail?.sampleImages}
                videos={venueDetail?.videos}
                // videos={sampleVenueDetail?.sampleVideos}
                venueId={venueDetail?._id}
              />

              {/* Event section */}
              {venueDetail?.eventTypes?.length > 0 && (
                <div className={classes.amenities}>
                  <h1 className={classes.title}>Events</h1>
                  <div className={classes.tagWrapper}>
                    {venueDetail?.eventTypes?.map((item) => (
                      <div className={classes.tag}>{item.eventTypeName}</div>
                    ))}
                    {/* {sampleVenueDetail?.amenities?.map((item) => (
                      <div className={classes.tag}>{item}</div>
                    ))} */}
                  </div>
                </div>
              )}

              {/* Venue description */}
              <div className={classes.description}>
                <h3 className={classes.heading}>
                  About {venueDetail?.name}
                  {/* About {sampleVenueDetail?.title} */}
                </h3>
                <p className={classes.paragraph}>
                  {venueDetail?.description}
                  {/* {sampleVenueDetail?.description} */}
                </p>
              </div>

              {/* Amenities section */}
              {venueDetail?.amenitiesNames?.length > 0 && (
                <div className={classes.amenities}>
                  <h1 className={classes.title}>Amenities</h1>
                  <div className={classes.tagWrapper}>
                    {venueDetail?.amenitiesNames?.map((item) => (
                      <div className={classes.tag}>{item.amenityName}</div>
                    ))}
                    {/* {sampleVenueDetail?.amenities?.map((item) => (
                      <div className={classes.tag}>{item}</div>
                    ))} */}
                  </div>
                </div>
              )}
            </div>

            {windowWidth >= 1024 && (
              // PriceCard for desktop screen
              <div className={classes.priceDetails}>
                <PriceDetail
                  details={venueDetail}
                  selectedAddOns={selectedAddOns}
                  setSelectedAddOns={setSelectedAddOns}
                  selectedInterval={selectedInterval}
                  setSelectedInterval={setSelectedInterval}
                  dayValue={dayValue}
                  setDayValue={setDayValue}
                  intervals={intervals}
                  setIntervals={setIntervals}
                  selectedDate={selectedDate}
                  setSelectedDate={setSelectedDate}
                  submitAddToCart={submitAddToCart}
                  addToCartLoading={addToCartLoading}
                  selectedSlotPrice={selectedSlotPrice}
                  setSelectedSlotPrice={setSelectedSlotPrice}
                  venueDetail = {venueDetail}
                  noOfPeople = {noOfPeople}
                  setNoOfPeople = {setNoOfPeople}
                  // details={sampleVenueDetail?.priceDetails}
                />
              </div>
            )}
          </div>

          {/* Review Section */}
          {/* <ReviewSection ratingsData={sampleVenueDetail?.ratingsData} /> */}

          {/* similar data sliders */}
          {similarInCategories.length > 0 && (
            <DefaultSlider
              data={similarInCategories}
              // data={data}
              title={`Similar ${venueDetail?.categoryName}`}
              containerClass={classes.slider}
            />
          )}
          {similarInLocations.length > 0 && (
            <DefaultSlider
              // data={data}
              data={similarInLocations}
              title="Similar Categories"
              containerClass={classes.slider}
            />
          )}
        </div>
      )}
    </>
  );
};

export default VenueDetail;
